import { editorLocalization } from "survey-creator-core";

export var huStrings = {
  //survey templates
  survey: {
    edit: "Szerkesztés",
    externalHelpLink: "Nézze meg, hogyan kell kérdőíveket készíteni.",
    externalHelpLinkUrl:
      "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Kérem dobjon ide egy kérdést a bal oldali menüből.",
    copy: "Másolás",
    addToToolbox: "Hozzáadás az eszköztárhoz",
    deletePanel: "Panel eltávolítása",
    deleteQuestion: "Kérdés eltávolítása",
    convertTo: "Konvertálás",
    drag: "Elem mozgatása"
  },
  //questionTypes
  qt: {
    default: "Alapértelmezett",
    checkbox: "Jelölőnégyzet",
    comment: "Megjegyzés",
    imagepicker: "Képválasztó",
    dropdown: "Legördülő",
    file: "Állomány",
    html: "HTML",
    matrix: "Mátrix (egyválasztós)",
    matrixdropdown: "Mátrix (többválasztós)",
    matrixdynamic: "Mátrix (dinamikus)",
    multipletext: "Többsoros szöveg",
    panel: "Panel",
    paneldynamic: "Panel (dinamikus)",
    radiogroup: "Választógomb",
    rating: "Értékelés",
    text: "Egysoros szöveg",
    boolean: "Igaz-hamis",
    expression: "Kifejezés (csak olvasható)",
    flowpanel: "Folyamat"
  },
  //Strings in Editor
  ed: {
    defaultLocale: "Alapértelmezett ({0})",
    survey: "Kérdőív",
    settings: "Kérdőívbeállítások",
    opjectPropertiesHeader: "Haladó",
    showObjectProperties: "Tulajdonságok mutatása",
    hideObjectProperties: "Tulajdonságok elrejtése",
    editSurvey: "Kérdőív szerkesztése",
    addNewPage: "Új lap felvétele",
    moveRight: "Görgetés jobbra",
    moveLeft: "Görgetés balra",
    deletePage: "Lap eltávolítása",
    editPage: "Lap szerkesztése",
    edit: "Szerkesztés",
    newPageName: "lap",
    newQuestionName: "kérdés",
    newPanelName: "panel",
    newTextItemName: "szöveg",
    testSurvey: "Próba",
    testSurveyAgain: "Próba ismét",
    testSurveyWidth: "Kérdőív szélessége: ",
    embedSurvey: "Kérdőív beágyazása",
    translation: "Fordítás",
    saveSurvey: "Kérdőív mentése",
    designer: "Kérdőívtervező",
    jsonEditor: "JSON szerkesztő",
    undo: "Mégse",
    redo: "Ismét",
    options: "Beállítások",
    generateValidJSON: "Érvényes JSON generálása",
    generateReadableJSON: "Olvasható JSON generálása",
    toolbox: "Eszköztár",
    toolboxGeneralCategory: "Általános",
    delSelObject: "Kijelölt elem eltávolítása",
    editSelObject: "Kijelölt elem szerkesztése",
    correctJSON: "Kérem javítsa a JSON-t.",
    surveyResults: "Kérdőív eredménye: ",
    surveyResultsTable: "Táblaként",
    surveyResultsJson: "JSON-ként",
    resultsTitle: "Kérdés címe",
    resultsName: "Kérdés megnevezése",
    resultsValue: "Válasz értéke",
    resultsDisplayValue: "Kijelzett értéke",
    modified: "Módosított",
    saving: "Mentés folyamatban",
    saved: "Mentve",
    saveError: "Hiba! A szerkesztő értéke nem került mentésre.",
    translationAddLanguage: "Válasszon egy nyelvet a fordításhoz.",
    translationShowAllStrings: "Összes szöveg mutatása",
    translationShowAllPages: "Összes lap mutatása",
    translationNoStrings: "Nincs fordítható szöveg. Kérem változtasson a szűrési feltételeken.",
    translationExportToSCVButton: "Mentés CSV-ként",
    translationImportFromSCVButton: "Betöltés CSV-ből.",
    translationMergeLocaleWithDefault: "{0} összefűzése az alapértelmezett szöveggel.",
    bold: "Félkövér",
    italic: "Dőlt",
    underline: "Aláhúzott",
    addNewQuestion: "Kérdés felvétele",
    completedHtmlOnConditionItemText: "Mutatás ha:"
  },
  //Property names in table headers
  pel: {
    isRequired: "Kötelező?"
  },
  //Property Editors
  pe: {
    apply: "Alkalmaz",
    ok: "OK",
    cancel: "Mégsem",
    reset: "Visszaállítás",
    close: "Bezárás",
    delete: "Eltávolítás",
    addNew: "Új felvétele",
    addItem: "Kattintson egy elem felvételéhez...",
    addOther: "Egyéb",
    addSelectAll: "Összes kijelölése",
    addNone: "Semmi",
    removeAll: "Összes eltávolítása",
    edit: "Szerkesztés",
    itemValueEdit: "Látható ha",
    editChoices: "Lehetőségek szerkesztése",
    move: "Mozgatás",
    empty: "<üres>",
    notEmpty: "<érték szerkesztése>",
    fastEntry: "Gyors felvétel",
    formEntry: "Felvétel űrlappal",
    testService: "Szolgáltatás tesztelése",
    conditionSelectQuestion: "Válasszon kérdést...",
    conditionValueQuestionTitle: "Kérem adjon meg, vagy válasszon értéket",
    conditionButtonAdd: "Felvétel",
    conditionButtonReplace: "Csere",
    conditionHelp:
      "Kérem adjon meg egy igaz-hamis kifejezést. Igaz visszatérési értékkel kell rendelkeznie, hogy a kérdés vagy a lap látható maradjon. Például: {question1} = 'value1' or ({question2} * {question4}  > 20 and {question3} < 5)",
    expressionHelp:
      "Kérem adjon meg egy kifejezést. Használjon kapcsos zárójeleket, hogy elérje a kérdések aktuális értékét. Például: '{question1} + {question2}', '({price}*{quantity}) * (100 - {discount})'",
    aceEditorHelp: "Nyomja meg a ctrl+space billentyűkombinációt a kifejezés befejezésére vonatkozó segítségért.",
    aceEditorRowTitle: "Jelenlegi sor",
    aceEditorPanelTitle: "Jelenlegi panel",
    showMore: "További információért kérem tekintse meg a dokumentációt",
    conditionShowMoreUrl:
      "https://surveyjs.io/Documentation/LibraryParameter?id=Question&parameter=visibleIf",
    assistantTitle: "Elérhető kérdések:",
    cellsEmptyRowsColumns: "Legalább egy sornak vagy egy oszlopnak lennie kell",

    propertyIsEmpty: "Kérem adjon meg egy értéket",
    value: "Érték",
    text: "Szöveg",
    rowid: "Sor ID",
    imageLink: "Kép URL",
    columnEdit: "Oszlop szerkesztése: {0}",
    itemEdit: "Elem szerkesztése: {0}",

    url: "URL",
    path: "Útvonal",
    valueName: "Érték megnevezése",
    titleName: "Cím megnevezése",

    showOtherItem: "Rendelkezik egyéb értékkel",
    otherText: "Az egyéb érték szövege",
    name: "Megnevezés",
    title: "Cím",
    cellType: "Cellatípus",
    colCount: "Oszlopok száma",
    choicesOrder: "Válassza ki a lehetőséget rendezését",
    visible: "Látható?",
    isRequired: "Kötelező?",
    startWithNewLine: "Új sorban kezdődik?",
    rows: "Sorok száma",
    placeholder: "Mező kitöltőszövege",
    showPreview: "A kép előnézete látható?",
    storeDataAsText: "Az állomány tartalmát tárolja a JSON-ben, szövegként",
    maxSize: "Maximális állományméret byte-ban",
    imageHeight: "Kép magassága",
    imageWidth: "Kép szélessége",
    rowCount: "Sorok száma",
    columnLayout: "Oszlopok elrendezése",
    addRowLocation: "'Új sor felvétele' gomb elhelyezése",
    addRowText: "'Új sor felvétele' gomb szövege",
    removeRowText: "'Sor eltávolítása' gomb szövege",
    rateMin: "Legkisebb szorzó",
    rateMax: "Legnagyobb szorzó",
    rateStep: "Szorzó lépésköze",
    minRateDescription: "Legkisebb szorzó leírása",
    maxRateDescription: "Legnagyobb szorzó leírása",
    inputType: "Mező típusa",
    optionsCaption: "Beállítások felirata",
    defaultValue: "Alapértelmezett érték",
    cellsDefaultRow: "Cellák alapértelmezett szövege",

    surveyEditorTitle: "Kérdőív beállításainak módosítása",
    qEditorTitle: "Szerkesztés: {0}",

    maxLength: "Maximális hossz",

    //survey
    showTitle: "Cím mutatása/elrejtése",
    locale: "Alapértelmezett nyelv",
    mode: "Mód (szerkesztés/megtekintés)",
    clearInvisibleValues: "Nemlátható értékek törlése",
    cookieName: "Süti megnevezése (a kérdőív csak egyszer kitölthető)",
    sendResultOnPageNext: "Kérdőív értékének küldése a következő lapra lépéskor",
    storeOthersAsComment: "Az 'egyéb' mező értékének tárolása külön mezőben",
    showPageTitles: "Lapok címének mutatása",
    showPageNumbers: "Lapok számának mutatása",
    pagePrevText: "'Előző lap' gomb felirata",
    pageNextText: "'Következő lap' gomb felirata",
    completeText: "'Befejezés' gomb felirata",
    startSurveyText: "'Kezdés' gomb felirata",
    showNavigationButtons: "Navigációs gombok mutatása (alapértelmezett navigáció)",
    showPrevButton: "'Előző lap' gomb mutatása",
    firstPageIsStarted: "Az megkezdett lap a kérdőív első oldala.",
    showCompletedPage: "Befejező szöveg mutatása a kérdőív befejezésekor",
    goNextPageAutomatic:
      "Minden kérdés megválaszolásakor automatikusan a következő lapra lépés",
    showProgressBar: "Előrehaladás-mutató megjelenítése",
    questionTitleLocation: "Kérdés címének helye",
    requiredText: "Kötelező szimbólum",
    questionStartIndex: "Kérdés kezdőindexe (1, 2 vagy 'A', 'a')",
    showQuestionNumbers: "Kérdés számának mutatása",
    questionTitleTemplate:
      "Kérdés címének sablonja: '{szám}. {kötelező} {cím}'",
    questionErrorLocation: "Kérdés hibaüzenetének helyzete",
    focusFirstQuestionAutomatic: "Első kérdés automatikus kijelölése lapváltás esetén",
    questionsOrder: "Elemek rendezése a lapon",
    maxTimeToFinish: "A kérdőív kitöltésére fordítható maximális idő",
    maxTimeToFinishPage: "Egy lap kitöltésére fordítható maximális idő",
    showTimerPanel: "Időzító mutatása",
    showTimerPanelMode: "Időzító módja",
    renderMode: "Megjelenítés módja",
    allowAddPanel: "Új panel hozzáadásának engedélyezése",
    allowRemovePanel: "Panel eltávolításának engedélyezése",
    panelAddText: "'Új panel felvétele' gomb szövege",
    panelRemoveText: "'Panel eltávolítása' gomb szövege",
    isSinglePage: "Minden elem mutatása egy lapon",
    html: "HTML",
    expression: "Kifejezés",

    minValue: "Minimális érték",
    maxValue: "Maximális érték",
    minLength: "Minimális hossz",
    allowDigits: "Számjegyek engedélyezése",
    minCount: "Minimális darabszám",
    maxCount: "Maximális darabszám",
    regex: "Reguláris kifejezés",

    tabs: {
      general: "Általános",
      fileOptions: "Beállítások",
      html: "HTML szerkesztő",
      columns: "Oszlopok",
      rows: "Sorok",
      choices: "Lehetőségek",
      items: "Elemek",
      visibleIf: "Látható ha",
      enableIf: "Engedélyezve ha",
      requiredIf: "Kötelező ha",
      rateValues: "Szorzó értékek",
      choicesByUrl: "Lehetőségek a webről",
      matrixChoices: "Alapértelmezett lehetőségek",
      multipleTextItems: "Szövegmezők",
      validators: "Validátorok",
      navigation: "Navigáció",
      question: "Kérdés",
      completedHtml: "Befejező szöveg (HTML)",
      completedHtmlOnCondition: "Befejző szöveg mutatásának feltétele",
      loadingHtml: "Betöltő szöveg (HTML)",
      timer: "Időzítő/Kvíz",
      triggers: "Eseményvezérlők",
      templateTitle: "Sablon címe"
    },
    editProperty: "Tulajdonság szerkesztése '{0}'",
    items: "[ Elemek: {0} ]",

    enterNewValue: "Kérem, adja meg az értéket.",
    noquestions: "Nincsenek kérdések a kérdőívben.",
    createtrigger: "Kérem hozzon létre egy eseményvezérlőt",
    triggerOn: "Be ",
    triggerMakePagesVisible: "Lapok láthatóvá tétele:",
    triggerMakeQuestionsVisible: "Elemek láthatóvá tétele:",
    triggerCompleteText: "Kérdőív befejezése, ha sikeres.",
    triggerNotSet: "Nincs eseménykezelő beállítva",
    triggerRunIf: "Futtatás ha",
    triggerSetToName: "A következő értékének cseréje: ",
    triggerFromName: "A következő értékének másolása: ",
    triggerRunExpression: "A következő kifejezés futtatása:",
    triggerSetValue: "erre: ",
    triggerIsVariable: "Ne mentse az eredményt a kérdőív eredményébe."
  },
  //Property values
  pv: {
    true: "igaz",
    false: "hamis",
    inherit: "öröklött",
    show: "mutatás",
    hide: "elrejtés",
    default: "alapértelmezett",
    initial: "kezdeti",
    random: "véletlen",
    collapsed: "csukott",
    expanded: "nyitott",
    none: "semmi",
    asc: "növekvő",
    desc: "csökkenő",
    indeterminate: "határozatlan",
    decimal: "deimális",
    currency: "pénz",
    percent: "százalék",
    firstExpanded: "első nyitva",
    off: "ki",
    onPanel: "a panelen",
    onSurvey: "a kérdőíven",
    list: "lista",
    progressTop: "felül",
    progressBottom: "alul",
    progressTopBottom: "alül és felül",
    horizontal: "horizontális",
    vertical: "vertikális",
    top: "fent",
    bottom: "lent",
    topBottom: "fent és lent",
    left: "bal",
    color: "szín",
    date: "dátum",
    datetime: "dátum és idő",
    "datetime-local": "helyi dátum és idő",
    email: "email",
    month: "hónap",
    number: "szám",
    password: "jelszó",
    range: "intervallum",
    tel: "telefon",
    text: "szöveg",
    time: "idő",
    url: "URL",
    week: "hét",
    hidden: "rejtett",
    on: "be",
    onPage: "a lapon",
    edit: "szerkesztés",
    display: "mutatás",
    onComplete: "befejezéskor",
    onHidden: "eltűnéskor",
    all: "összes",
    page: "lap",
    survey: "kérdőív",
    onNextPage: "a következő lapra lépéskor",
    onValueChanged: "az érték változásakor"
  },
  //Operators
  op: {
    empty: "üres",
    notempty: "nem üres",
    equal: "megegyezik",
    notequal: "nem egyezik meg",
    contains: "tartalmazza",
    notcontains: "nem tartalmazza",
    greater: "nagyobb mint",
    less: "kisebb mint",
    greaterorequal: "nagyobb vagy egyenlő",
    lessorequal: "kisebb vagy egyenlő"
  },
  //Embed window
  ew: {
    angular: "Angular verzió használata",
    jquery: "jQuery verzió használata",
    knockout: "Knockout verzió használata",
    react: "React verzió használata",
    vue: "Vue verzió használata",
    bootstrap: "Használat Bootstrap frameworkkel",
    standard: "Használat Bootstrap framework nélkül",
    showOnPage: "Kérdőív mutatása a lapon",
    showInWindow: "Kérdőív mutatása egy ablakban",
    loadFromServer: "Kérdőív JSON betöltése a szerverről",
    titleScript: "Szkriptek és stíluslapok",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  //Preview (Survey)
  ts: {
    selectPage: "Válassza ki a tesztelni kívánt lapot:",
    showInvisibleElements: "Nemlátható elemek megjelnítése"
  },
  validators: {
    answercountvalidator: "válaszok száma",
    emailvalidator: "e-mail",
    expressionvalidator: "kifejezés",
    numericvalidator: "szám",
    regexvalidator: "reguláris kifejezés",
    textvalidator: "szöveg"
  },
  triggers: {
    completetrigger: "kérdőív befejezése",
    setvaluetrigger: "érték beállítása",
    copyvaluetrigger: "érték másolása",
    runexpressiontrigger: "kifejezés futtatása",
    visibletrigger: "láthatóság megváltoztatása (már nincs használatban)"
  },
  //Properties
  p: {
    name: "Megnevezés",
    title: {
      name: "Cím",
      title: "Hagyja üresen, ha megegyezik a 'Megnevezés'-el"
    },

    navigationButtonsVisibility: "Navigációs gombok láthatósága",
    questionsOrder: "Kérdések rendezése",
    maxTimeToFinish: "Kitöltése fordítható maximális idő",

    visible: "Látható",
    visibleIf: "Látható ha",
    questionTitleLocation: "Kérdés címének elhelyezése",
    description: "Leírás",
    state: "Állapot",
    isRequired: "Kötelező",
    requiredIf: "Kötelező ha",
    indent: "Bekezdés",
    requiredErrorText: "Kötelező hibaüzenet",
    startWithNewLine: "Új sorban kezdődik",
    innerIndent: "Belső bekezdés",
    page: "Lap",

    width: "Szélesség",

    commentText: "Megjegyzés szövege",
    valueName: "Érték megnevezése",
    enableIf: "Engedélyezve ha",
    defaultValue: "Alapértelmezett érték",
    correctAnswer: "Helyes érték",
    readOnly: "Csak olvasható",
    validators: "Validátorok",
    titleLocation: "Cím elhelyezése",

    showCommentArea: "Van megjegyzés",
    showOtherItem: "Van 'egyéb'",
    choices: "Lehetőségek",
    choicesOrder: "Lehetőségek rendezése",
    choicesByUrl: "Lehetőségek a webről",
    otherText: "'Egyéb' szöveg",
    otherErrorText: "'Egyéb' hibaüzenet",
    storeOthersAsComment: "'Egyéb' tárolása megjegyzésként",

    label: "Cimke",
    showTitle: "Cím mutatása",
    valueTrue: "Érték igaz",
    valueFalse: "Érték hamis",

    cols: "Oszlopok",
    rows: "Sorok",
    placeholder: "Helykitöltő",

    optionsCaption: "'Beállítások' felirata",

    expression: "Kifejezés",
    format: "Formátum",
    displayStyle: "Megjelenítés stílusa",
    currency: "Pénz",
    useGrouping: "Csoportosítás",

    showPreview: "Előnézet mutatása",
    allowMultiple: "Több engedélyezése",
    imageHeight: "Kép magassága",
    imageWidth: "Kép szélessége",
    storeDataAsText: "'Egyéb' tárolása szövegként",
    maxSize: "Maximális méret",

    html: "HTML",

    columns: "Oszlopok",
    cells: "Cellák",
    isAllRowRequired: "Minden sor kötelező",

    horizontalScroll: "Horizontális görgetés",
    cellType: "Cellatípus",
    columnsLayout: "Oszlopok elrendezése",
    columnColCount: "Oszlopok száma",
    columnMinWidth: "Oszlop minimális szélessége",

    rowCount: "Sorok száma",
    minRowCount: "Sorok minimális száma",
    maxRowCount: "Sorok maximális száma",
    keyName: "Kulcs megnevezése",
    keyDuplicationError: "Kulcs duplikációs hibaüzenete",
    confirmDelete: "Eltávolítás megerősítése",
    confirmDeleteText: "Eltávolíáts megerősítése kérdés",
    addRowLocation: "'Új sor felvétele' gomb elhelyezése",
    addRowText: "'Új sor felvétele' gomb szövege",
    removeRowText: "'Sor eltávolítása' gomb szövege",

    items: "Elemek",
    itemSize: "Elem mérete",
    colCount: "Oszlopok száma",

    templateTitle: "Sablon címe",
    templateDescription: "Sablon leírása",
    allowAddPanel: "Új panel felvételének engedélyezése",
    allowRemovePanel: "Panel eltávolításának engedélyezése",
    panelCount: "Panelek száma",
    minPanelCount: "Panelek minimális száma",
    maxPanelCount: "Panelek maximális száma",
    panelsState: "Panelek állapota",
    panelAddText: "'Új panel felvétele' gomb szövege",
    panelRemoveText: "'Panel eltávolítása' gomb szövege",
    panelPrevText: "'Előző panel' gomb szövege",
    panelNextText: "'Következő panel' szövege",
    showQuestionNumbers: "Kérdés számának mutatása",
    showRangeInProgress: "Előrehaladás-mutató ",
    renderMode: "Megjelenítés módja",
    templateTitleLocation: "Sablon címének elhelyezése",

    rateValues: "Szorzó értékek",
    rateMin: "Legkisebb szorzó",
    rateMax: "Legnagyobb szorzó",
    rateStep: "Szorzó lépésköze",
    minRateDescription: "Legkisebb szorzó leírása",
    maxRateDescription: "Legnagyobb szorzó leírása",

    inputType: "Mező típusa",
    size: "Méret",

    locale: "Alapértelmezett nyelv",
    focusFirstQuestionAutomatic: "Első kérdés automatikus kijelölése lapváltás esetén",
    completedHtml: "Befejező szöveg (HTML)",
    completedBeforeHtml: "Befejezés előtti szöveg (HTML)",
    loadingHtml: "Betöltő szöveg (HTML)",
    triggers: "Eseményvezérlők",
    cookieName: "Süti megnevezése",
    sendResultOnPageNext: "Kérdőív értékének küldése a következő lapra lépéskor",
    showNavigationButtons: "Navigációs gombok mutatása (alapértelmezett navigáció)",
    showPrevButton: "'Előző lap' gomb mutatása",
    showPageTitles: "Lapok címének mutatása",
    showCompletedPage: "Befejező szöveg mutatása a kérdőív befejezésekor",
    showPageNumbers: "Lapok számának mutatása",
    questionErrorLocation: "Kérdés hibaüzenetének helyzete",
    showProgressBar: "Előrehaladás-mutató megjelenítése",
    mode: "Mód (szerkesztés/megtekintés)",
    goNextPageAutomatic:
      "Minden kérdés megválaszolásakor automatikusan a következő lapra lépés",
    checkErrorsMode: "Hibavizsgálat",
    clearInvisibleValues: "Nemlátható értékek törlése",
    startSurveyText: "'Kezdés' gomb felirata",
    pagePrevText: "'Előző lap' gomb felirata",
    pageNextText: "'Következő lap' gomb felirata",
    completeText: "'Befejezés' gomb felirata",
    requiredText: "Kötelező szimbólum",
    questionStartIndex: "Kérdés kezdőindexe (1, 2 vagy 'A', 'a')",
    questionTitleTemplate:
      "Kérdés címének sablonja: '{szám}. {kötelező} {cím}'",
    firstPageIsStarted: "Az megkezdett lap a kérdőív első oldala.",
    isSinglePage: "Minden elem mutatása egy lapon",
    maxTimeToFinishPage: "Egy lap kitöltésére fordítható maximális idő",
    showTimerPanel: "Időzítő mutatása",
    showTimerPanelMode: "Időzítő módja",

    defaultPanelValue: "Panel alapértelmezett értéke",
    defaultRowValue: "Sor alapértelmezett értéke",
    showNoneItem: "Lehet üres",
    noneText: "Üres szöveg",

    text: "Szöveg"
  }
};
editorLocalization.locales["hu"] = huStrings;
